import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Supplier from "./Supplier"

function getSupplierList(data) {
  const supplierArray = []
  data.allLeverandoererJson.edges.forEach(item =>
    supplierArray.push(
      <Supplier
        image={item.node.firmaLogo}
        navn={item.node.firmaNavn}
        beskrivelse={item.node.firmaBeskrivelse}
        url={item.node.firmaURL}
        underkategori={item.node.firmaUnderkategori}
      />
    )
  )
  return supplierArray
}

const Nuklearmedicin = ({ children }) => (
    <StaticQuery
      query={graphql`
            query Nuklearmedicin {
              allLeverandoererJson(filter: {firmaOverkategori: {eq: "Nuklearmedicin"}}, sort: {fields: firmaNavn, order: ASC}) {
                edges {
                  node {
                    firmaLogo { 
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                    firmaNavn
                    firmaBeskrivelse
                    firmaURL
                    firmaUnderkategori
                  }
                }
              }
            }
            `}
      render={data => <section className="easymed__grid__wrapper">{getSupplierList(data)}</section>}
    />
)

export default Nuklearmedicin