import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

class Supplier extends Component {
  render() {
    return (
        <div className="easymed__grid__single">
        <a href={this.props.url} alt={this.props.navn} target="_blank" rel="noopener noreferrer">
        <GatsbyImage 
          /*  OLD Syntax: <Img fixed={data.file.childImageSharp.fixed} />
              NEW Syntax: <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} /> */
            image={this.props.image.childImageSharp.gatsbyImageData}
            imgStyle={{
                objectFit: "contain",
                objectPosition: "top",
            }}
            alt={this.props.navn}
            key={this.props.id} />
        <p>{this.props.beskrivelse}</p>
        </a>
        </div>
    )
  }
}

export default Supplier