import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Nuklearmedicin from "../../components/Nuklearmedicin.js"
import Knap from "../../components/Knap.js"

const NuklearmedicinPage = () => (
    <LayoutComponent>
        <h1>Nukleærmedisin</h1>
        <p>I dag kan du ikke lenger si røntgentilbehør eller røntgenbeskyttelse uten også å nevne nukleærmedisin. Vi har knyttet vår mangeårig erfaring med salg av røntgentilbehør til også å inkludere røntgenbeskyttelse i form av blybeskyttelse, blyavdekning, røntgenforklær, blyforklær, røntgenbriller og blyskjold, samt treningsfantomer, kvalitetskontroll (QA), pasientsimulering og vevssimulering til nukleærmedisin.</p>
        <h1>Våre leverandører</h1>
        <Nuklearmedicin />
        <Knap />
    </LayoutComponent>
)
export default NuklearmedicinPage